import { ASSET_PATH } from './ImageUtil';

export type DownloadType = Partial<{
    name: string;
    ext: string;
    hash: string;
    size: string;
    url?: string;
}>;

const DownloadUtil = {
    getFileUrl: (download: DownloadType) => {
        if (download.url) return download.url;

        if (!download?.hash || !download?.ext) return '#';

        return `${ASSET_PATH}${download.hash}${download.ext}`;
    },
};

export default DownloadUtil;
