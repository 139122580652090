import { BaseModule } from '../../types';
import React from 'react';
import DownloadUtil, { DownloadType } from '../../util/DownloadUtil';

const formatFileInfo = (download: DownloadType) => {
    if (!download || !download.ext) return null;

    const sizeInt = parseInt(download.size!);
    let sizeText = '';
    if (sizeInt < 950) {
        sizeText = sizeInt.toString() + ' KB';
    } else {
        sizeText = Math.round(sizeInt / 1000).toString() + ' MB';
    }

    const extText = download.ext.substring(1);

    return (
        <React.Fragment>
            (<span className="m-download-list__datatype">{extText}</span>,&nbsp;
            {sizeText})
        </React.Fragment>
    );
};

const DownloadItem: React.FC<{
    content: {
        download?: DownloadType;
        downloadOld?: DownloadType;
        title?: string;
    };
}> = ({ content }) => {
    const download = content.download || content.downloadOld;
    if (!download) return null;

    return (
        <li>
            <a
                className="a-link"
                target="_blank"
                download={download.name}
                rel="noopener noreferrer"
                href={DownloadUtil.getFileUrl(download)}
            >
                {content.title || 'Download'}
            </a>
            &nbsp;
            {formatFileInfo(download)}
        </li>
    );
};

const Downloads: BaseModule = ({ pageContent, moduleHead }) => {
    return (
        <div className="downloadArea abstractComponent">
            <div className="m-download-list " data-t-name="DownloadList">
                {moduleHead}
                <ul>
                    {pageContent.items?.map((item, idx) => {
                        return <DownloadItem key={idx} content={item} />;
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Downloads;
